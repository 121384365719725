import { TFunction } from 'i18next';

import { User } from 'types/user';
import { ItemType } from 'types/payment';
import { ShippingAddress, Subscription } from 'types/subscription';
import { AttachedOffers, Offer, OfferPrice } from 'types/offer';
import { AllowedCountries, CountryCodeOption } from 'types/physicalProducts';
import {
  DynamicContextualData,
  FormData,
  KetoChocolateBarsAddressData,
  RowFields,
  RowVariantsEnum,
} from 'types/ketoChocolateBars';

import settings from './settings';
import { getStandardOfferPrice, isOfferSubscription } from './offerHelper';
import {
  getAllCountryPhoneCodes,
  getCountryPhoneCodeByPhonePrefix,
} from './physicalProductHelper';
import {
  getActiveSubscriptionByItemType,
  getUnsubscribedSubscriptionByItemType,
  isAccessAvailable,
} from './userHelper';

export type ReceiptType = 'subscription' | 'single';

export type ReceiptValues = {
  boxCount: number;
  singleAmount: number;
  barPrice: string;
  total: number;
  currency: string;
};

export type ReceiptItems = {
  subscription: ReceiptValues;
  single: ReceiptValues;
};

export const KETO_CHOCOLATE_BARS_LOCALES: string[] = [
  'fr',
  'cs',
  'da',
  'ro',
  'sv',
  'pl',
  'it',
  'hr',
  'bg',
  'fi',
  'sk',
  'el',
  'de',
  'et',
  'pt',
  'lt',
  'hu',
  'nl',
  'es',
];

export const KETO_CHOCOLATE_BARS_ALLOWED_COUNTRIES: AllowedCountries = [
  {
    value: 'DK',
    emoji: '🇩🇰',
    name: 'Denmark',
  },
  {
    value: 'FI',
    emoji: '🇫🇮',
    name: 'Finland',
  },
  {
    value: 'FR',
    emoji: '🇫🇷',
    name: 'France',
  },
  {
    value: 'DE',
    emoji: '🇩🇪',
    name: 'Germany',
  },
  {
    value: 'GR',
    emoji: '🇬🇷',
    name: 'Greece',
  },
  {
    value: 'IT',
    emoji: '🇮🇹',
    name: 'Italy',
  },
  {
    value: 'NL',
    emoji: '🇳🇱',
    name: 'Netherlands',
  },
  {
    value: 'PT',
    emoji: '🇵🇹',
    name: 'Portugal',
  },
  {
    value: 'CZ',
    emoji: '🇨🇿',
    name: 'Chech',
  },
  {
    value: 'RO',
    emoji: '🇷🇴',
    name: 'Romania',
  },
  {
    value: 'SE',
    emoji: '🇸🇪',
    name: 'Sweden',
  },
  {
    value: 'PL',
    emoji: '🇵🇱',
    name: 'Poland',
  },
  {
    value: 'HR',
    emoji: '🇭🇷',
    name: 'Croatia',
  },
  {
    value: 'BG',
    emoji: '🇧🇬',
    name: 'Bulgaria',
  },
  {
    value: 'SK',
    emoji: '🇸🇰',
    name: 'Slovakia',
  },
  {
    value: 'EE',
    emoji: '🇪🇪',
    name: 'Estonia',
  },
  {
    value: 'LT',
    emoji: '🇱🇹',
    name: 'Lithuania',
  },
  {
    value: 'LV',
    emoji: '🇱🇻',
    name: 'Latvia',
  },
  {
    value: 'HU',
    emoji: '🇭🇺',
    name: 'Hungary',
  },
  {
    value: 'AT',
    emoji: '🇦🇹',
    name: 'Austria',
  },
  {
    value: 'BE',
    emoji: '🇧🇪',
    name: 'Belgium',
  },
  {
    value: 'IE',
    emoji: '🇮🇪',
    name: 'Ireland',
  },
  {
    value: 'LU',
    emoji: '🇱🇺',
    name: 'Luxembourg',
  },
  {
    value: 'SI',
    emoji: '🇸🇮',
    name: 'Slovenia',
  },
  {
    value: 'ES',
    emoji: '🇪🇸',
    name: 'Spain',
  },
];

export const getKetoCountryFullName = (countryCode: string): string => {
  const country = KETO_CHOCOLATE_BARS_ALLOWED_COUNTRIES.find(
    (c) => c.value === countryCode
  );

  return country ? country.name : '';
};

const FALLBACK_COUNTRY = 'ES';

export const getIntialCountry = (
  user: User,
  isConfirmationEditable?: boolean,
  confirmationData?: KetoChocolateBarsAddressData | null
): string => {
  if (isConfirmationEditable && confirmationData?.country) {
    return confirmationData?.country;
  }

  const userCountry = user.country || user.origin_country;
  const allowedCountries = KETO_CHOCOLATE_BARS_ALLOWED_COUNTRIES.map(
    (country) => country.value
  );

  return allowedCountries.includes(userCountry)
    ? userCountry
    : FALLBACK_COUNTRY;
};

export const getInitialPhoneCountry = (
  userCountry: string,
  isConfirmationEditable?: boolean,
  confirmationData?: KetoChocolateBarsAddressData | null
) => {
  if (isConfirmationEditable && confirmationData?.phone_prefix) {
    return getCountryPhoneCodeByPhonePrefix(confirmationData?.phone_prefix)
      .value;
  }

  return userCountry || '';
};

export const sortCountriesAlphabetically = (
  countries: AllowedCountries
): AllowedCountries => countries.sort((a, b) => a.name.localeCompare(b.name));

export const isKetoChocolateBarsEnabled = (): boolean =>
  settings.KET_CHOCOLATE_BARS_ENABLED === 'true';

export const isKetoChocolateBarsAllowed = (user: User): boolean => {
  if (!isKetoChocolateBarsEnabled()) return false;
  if (!KETO_CHOCOLATE_BARS_LOCALES.includes(user.locale)) return false;

  return true;
};

export enum KETO_CHOCOLATE_OFFER_ACTIVITY_SOURCE {
  announcement1 = 'announcement_1',
  announcement2 = 'announcement_2',
  announcement3 = 'announcement_3',
  announcement4 = 'announcement_4',
  announcement5 = 'announcement_5',
  fullOffer = 'full_offer',
  addressInput = 'address_input',
  outOfStockModal = 'out_of_stock_modal',
  contextualOfferProgram = 'contextual_offer_program',
  contextualOfferProfile = 'contextual_offer_profile',
  paymentSuccessModal = 'payment_success_modal',
  paymentFailModal = 'payment_fail_modal',
  postponeModal = 'postpone_modal',
  addressConfirmationPage = 'address_confirmation_page',
  addressInputSubmit = 'address_input_submit',
  addressInputEdit = 'address_input_edit',
  editChangesSavedModal = 'edit_changes_saved_modal',
  submitChangesSavedModal = 'submit_changes_saved_modal',
  unsubscribeModal = 'unsubscribe_modal',
}

export enum KETO_CHOCOLATE_OFFER_ACTIVITY_EVENT {
  open = 'open',
  positiveAnswer = 'positive_answer',
  negativeAnswer = 'negative_answer',
  declineOffer = 'decline_offer',
  closeModal = 'close_modal',
  commitPurchase = 'commit_purchase',
  swipeTestimonials = 'swipe_testimonials',
  subscribeOfferBuy = 'subscribe_offer_buy',
  oneTimeOrderBuy = 'one_time_order_buy',
  toggleSubscribe = 'toggle_subscribe',
  toggleSingle = 'toggle_buy_once',
  close = 'close',
  continue = 'continue',
  confirmPostponing = 'confirm_postponing',
  backToShippingAddress = 'back_to_shipping_address',
  submitAddressSuccess = 'submit_address_success',
  submitAddressFailed = 'submit_address_failed',
  shippingAddressSubmitted = 'shipping_address_submitted',
  editSubscription = 'edit_subscription',
  unsubscribe = 'unsubscribe',
  back = 'back',
  keepSubscription = 'keep_subscription',
  postponeAddress = 'postpone_address',
}

export const isOfferRegistration = (offer: Offer): boolean =>
  offer.offer_type.includes('registration');

export const getInitialSubscriptionOffer = (attachedOffers: AttachedOffers) =>
  attachedOffers.find((offer) => {
    const standardOfferPrice = getStandardOfferPrice(offer);
    if (!isOfferSubscription(standardOfferPrice as OfferPrice)) {
      return null;
    }

    return offer;
  });

export const getInitialSingleOffer = (attachedOffers: AttachedOffers) =>
  attachedOffers.find((offer) => {
    const standardOfferPrice = getStandardOfferPrice(offer);
    if (isOfferSubscription(standardOfferPrice as OfferPrice)) {
      return null;
    }

    return offer;
  });

export const generateRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const TRANSLATIONS_KEYS: { [key: string]: string } = {
  KetoChocolateBarsV2: 'ketochocolatebarsv2',
  KetoChocolateBarsV3: 'ketochocolatebarsv3',
};

export const getTranslationKey = (
  templateName: 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3'
): string => TRANSLATIONS_KEYS[templateName];

export const getProgramPageTranslationKey = (
  template: 'KetoChocolateBars' | 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3',
  isLyteBarsEnabled: boolean
): string => {
  const translationKeys = {
    KetoChocolateBars: 'ketochocolatebars',
    KetoChocolateBarsV2: 'ketochocolatebarsv2',
    KetoChocolateBarsV3: 'ketochocolatebarsv3',
  };

  if (isLyteBarsEnabled) {
    return 'lytebars';
  }

  return translationKeys[template];
};

export const retrieveTranslationsKeyFromQueryParams = (queryParams: any) => {
  const allowedTemplates = ['KetoChocolateBarsV2', 'KetoChocolateBarsV3'];

  if (
    queryParams.template_name &&
    allowedTemplates.includes(queryParams.template_name)
  ) {
    return getTranslationKey(queryParams.template_name);
  }

  return 'ketochocolatebars';
};

export const retrieveTranslationKeyFromOffer = (offer: Offer) => {
  const allowedTemplates = ['KetoChocolateBarsV2', 'KetoChocolateBarsV3'];

  if (offer.template_name && allowedTemplates.includes(offer.template_name)) {
    return getTranslationKey(
      offer.template_name as 'KetoChocolateBarsV2' | 'KetoChocolateBarsV3'
    );
  }

  return 'ketochocolatebars';
};

export const isZipFieldNeeded = (queryParams: any) => {
  const allowedTemplates = ['KetoChocolateBarsV2', 'KetoChocolateBarsV3'];

  if (
    queryParams.template_name &&
    allowedTemplates.includes(queryParams.template_name)
  ) {
    return true;
  }

  return false;
};

export const getInitialShippingAddressFromFields = (
  subscription: Subscription,
  countryChanged: boolean,
  formData?: FormData
) => {
  const shippingInfo = subscription.shipping_address as ShippingAddress;

  const genericInitialFormFields: FormData = {
    email: shippingInfo.email || '',
    first_name: shippingInfo.first_name || '',
    last_name: shippingInfo.last_name || '',
    address: shippingInfo.address || '',
    address2: shippingInfo.address2 || '',
    zip: shippingInfo.zip || '',
    city: shippingInfo.city || '',
    phone_number: shippingInfo.phone_number || '',
    google_place_id: shippingInfo.google_place_id || '',
  };

  if (countryChanged) {
    genericInitialFormFields.email = formData?.email || '';
    genericInitialFormFields.first_name = formData?.first_name || '';
    genericInitialFormFields.last_name = formData?.last_name || '';
    genericInitialFormFields.phone_number = formData?.phone_number || '';
    genericInitialFormFields.address = '';
    genericInitialFormFields.address2 = '';
    genericInitialFormFields.zip = '';
    genericInitialFormFields.city = '';
    genericInitialFormFields.phone_number = shippingInfo.phone_number || '';
    genericInitialFormFields.google_place_id = '';
  }

  const initialCountryFields: { [key: string]: FormData } = {
    EN: genericInitialFormFields,
    GR: genericInitialFormFields,
    FR: genericInitialFormFields,
    SK: genericInitialFormFields,
    BG: genericInitialFormFields,
    SE: genericInitialFormFields,
    FI: genericInitialFormFields,
    HR: genericInitialFormFields,
    EE: genericInitialFormFields,
    AT: genericInitialFormFields,
    DE: genericInitialFormFields,
    NL: genericInitialFormFields,
    BE: genericInitialFormFields,
    SI: genericInitialFormFields,
    CZ: genericInitialFormFields,
    PL: genericInitialFormFields,
    DK: genericInitialFormFields,
    LV: genericInitialFormFields,
    LT: genericInitialFormFields,
    LU: genericInitialFormFields,
    HU: genericInitialFormFields,
    IE: {
      ...genericInitialFormFields,
      county: shippingInfo.county || '',
    },
    PT: {
      ...genericInitialFormFields,
      region: shippingInfo.region || '',
    },
    RO: {
      ...genericInitialFormFields,
      county: shippingInfo.county || '',
    },
    ES: {
      ...genericInitialFormFields,
      province: shippingInfo.province || '',
    },
    IT: {
      ...genericInitialFormFields,
      province: shippingInfo.province || '',
    },
  };

  return initialCountryFields[shippingInfo.country] || {};
};

export const getInitialFormFields = (
  user: User,
  country: string,
  countryChanged: boolean,
  formData?: FormData,
  isConfirmationEditable?: boolean,
  confirmationData?: KetoChocolateBarsAddressData | null
): FormData => {
  let genericInitialFormFields: FormData = {
    email: user.email || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    address: '',
    address2: '',
    zip: '',
    city: '',
    phone_number: user.phone_number || '',
    google_place_id: '',
  };
  if (isConfirmationEditable && confirmationData) {
    genericInitialFormFields = {
      ...genericInitialFormFields,
      ...confirmationData,
    };
  }
  if (countryChanged) {
    genericInitialFormFields.email = formData?.email || '';
    genericInitialFormFields.first_name = formData?.first_name || '';
    genericInitialFormFields.last_name = formData?.last_name || '';
    genericInitialFormFields.phone_number = formData?.phone_number || '';
  }

  const getInitialDynamicFieldValue = (
    fieldName: keyof KetoChocolateBarsAddressData
  ): string | any => {
    if (isConfirmationEditable && confirmationData) {
      return confirmationData[fieldName];
    }

    return '';
  };

  const initialCountryFields: { [key: string]: FormData } = {
    EN: genericInitialFormFields,
    GR: genericInitialFormFields,
    FR: genericInitialFormFields,
    SK: genericInitialFormFields,
    BG: genericInitialFormFields,
    SE: genericInitialFormFields,
    FI: genericInitialFormFields,
    HR: genericInitialFormFields,
    EE: genericInitialFormFields,
    AT: genericInitialFormFields,
    DE: genericInitialFormFields,
    NL: genericInitialFormFields,
    BE: genericInitialFormFields,
    SI: genericInitialFormFields,
    CZ: genericInitialFormFields,
    PL: genericInitialFormFields,
    DK: genericInitialFormFields,
    LV: genericInitialFormFields,
    LT: genericInitialFormFields,
    LU: genericInitialFormFields,
    HU: genericInitialFormFields,
    IE: {
      ...genericInitialFormFields,
      county: getInitialDynamicFieldValue('county'),
    },
    PT: {
      ...genericInitialFormFields,
      region: getInitialDynamicFieldValue('region'),
    },
    RO: {
      ...genericInitialFormFields,
      county: getInitialDynamicFieldValue('county'),
    },
    ES: {
      ...genericInitialFormFields,
      province: getInitialDynamicFieldValue('province'),
    },
    IT: {
      ...genericInitialFormFields,
      province: getInitialDynamicFieldValue('province'),
    },
  };

  return initialCountryFields[country] || {};
};

type CountryFields = {
  [countryCode: string]: RowFields[];
};

export const getFormFieldsForCountry = (country: string) => {
  const genericFormFieldTemplate = [
    {
      variant: RowVariantsEnum.sideBySide,
      fields: [
        {
          name: 'first_name',
          label: 'name',
          inputType: 'text',
          required: true,
          customStyle: 'semiBigMargin',
        },
        {
          name: 'last_name',
          label: 'last_name',
          inputType: 'text',
          required: true,
          customStyle: 'semiBigMargin',
        },
      ],
    },
    {
      variant: RowVariantsEnum.full,
      fields: [
        {
          name: 'address',
          label: 'address',
          inputType: 'google',
          required: true,
          customStyle: 'smallMargin',
        },
      ],
    },
    {
      variant: RowVariantsEnum.full,
      fields: [
        {
          name: 'address2',
          label: 'apartment',
          inputType: 'text',
          required: false,
          customStyle: 'smallMargin',
        },
      ],
    },
    {
      variant: RowVariantsEnum.full,
      fields: [
        {
          name: 'zip',
          label: 'zip',
          inputType: 'text',
          required: true,
          customStyle: 'smallMargin',
        },
      ],
    },
    {
      variant: RowVariantsEnum.full,
      fields: [
        {
          name: 'city',
          label: 'city',
          inputType: 'text',
          required: true,
          customStyle: 'semiBigMargin',
        },
      ],
    },
    {
      variant: RowVariantsEnum.sideBySide,
      fields: [
        {
          name: 'phone_prefix',
          label: 'country_code',
          inputType: 'phone-country-dropdown',
          required: true,
          customStyle: 'maxWidth140',
        },
        {
          name: 'phone_number',
          label: 'phone',
          inputType: 'number',
          required: true,
          customStyle: '',
        },
      ],
    },
  ];

  const dynamicCountryFields: CountryFields = {
    EN: genericFormFieldTemplate,
    GR: genericFormFieldTemplate,
    FR: genericFormFieldTemplate,
    SK: genericFormFieldTemplate,
    BG: genericFormFieldTemplate,
    SE: genericFormFieldTemplate,
    FI: genericFormFieldTemplate,
    HR: genericFormFieldTemplate,
    EE: genericFormFieldTemplate,
    AT: genericFormFieldTemplate,
    DE: genericFormFieldTemplate,
    NL: genericFormFieldTemplate,
    BE: genericFormFieldTemplate,
    SI: genericFormFieldTemplate,
    CZ: genericFormFieldTemplate,
    PL: genericFormFieldTemplate,
    DK: genericFormFieldTemplate,
    LU: genericFormFieldTemplate,
    LV: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    LT: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    HU: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    IE: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'county',
            label: 'county',
            inputType: 'dynamic-dropdown',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    PT: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'region',
            label: 'region',
            inputType: 'dynamic-dropdown',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    RO: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'county',
            label: 'county',
            inputType: 'dynamic-dropdown',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    ES: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'province',
            label: 'province',
            inputType: 'dynamic-dropdown',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
    IT: [
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'first_name',
            label: 'name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
          {
            name: 'last_name',
            label: 'last_name',
            inputType: 'text',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address',
            label: 'address',
            inputType: 'google',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'address2',
            label: 'apartment',
            inputType: 'text',
            required: false,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'zip',
            label: 'zip',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'city',
            label: 'city',
            inputType: 'text',
            required: true,
            customStyle: 'smallMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.full,
        fields: [
          {
            name: 'province',
            label: 'province',
            inputType: 'dynamic-dropdown',
            required: true,
            customStyle: 'semiBigMargin',
          },
        ],
      },
      {
        variant: RowVariantsEnum.sideBySide,
        fields: [
          {
            name: 'phone_prefix',
            label: 'country_code',
            inputType: 'phone-country-dropdown',
            required: true,
            customStyle: 'maxWidth140',
          },
          {
            name: 'phone_number',
            label: 'phone',
            inputType: 'number',
            required: true,
          },
        ],
      },
    ],
  };

  return dynamicCountryFields[country] || [];
};

export const IE_COUNTIES = [
  'Carlow',
  'Cavan',
  'Clare',
  'Cork',
  'Donegal',
  'Dublin',
  'Galway',
  'Kerry',
  'Kildare',
  'Kilkenny',
  'Laois',
  'Leitrim',
  'Limerick',
  'Longford',
  'Louth',
  'Mayo',
  'Meath',
  'Monaghan',
  'Offaly',
  'Roscommon',
  'Sligo',
  'Tipperary',
  'Waterford',
  'Westmeath',
  'Wexford',
  'Wicklow',
];

export const PT_REGIONS = [
  'Azores',
  'Aveiro',
  'Beja',
  'Braga',
  'Bragança',
  'Castelo Branco',
  'Coimbra',
  'Évora',
  'Faro',
  'Guarda',
  'Leiria',
  'Lisbon',
  'Madeira',
  'Portalegre',
  'Porto',
  'Santarém',
  'Setúbal',
  'Viana do Castelo',
  'Vila Real',
  'Viseu',
];

export const RO_CONTIES = [
  'Alba',
  'Arad',
  'Argeş',
  'Bacău',
  'Bihor',
  'Bistriţa-Năsăud',
  'Botoşani',
  'Braşov',
  'Brăila',
  'București',
  'Buzău',
  'Călăraşi',
  'Caraş-Severin',
  'Cluj',
  'Constanţa',
  'Covasna',
  'Dâmboviţa',
  'Dolj',
  'Galaţi',
  'Giurgiu',
  'Gorj',
  'Harghita',
  'Hunedoara',
  'laşi',
  'lalomiţa',
  'Ilfov',
  'Maramureş',
  'Mehedinţi',
  'Mureş',
  'Neamţ',
  'Olt',
  'Prahova',
  'Sălaj',
  'Satu Mare',
  'Sibiu',
  'Suceava',
  'Teleorman',
  'Timiş',
  'Tulcea',
  'Vâlcea',
  'Vaslui',
  'Vrancea',
];

export const ES_PROVINCES = [
  'A Coruña ',
  'Álava',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias Province',
  'Ávila',
  'Badajoz',
  'Balears Province',
  'Barcelona',
  'Burgos Cáceres',
  'Cádiz',
  'Cantabria Province',
  'Castellón',
  'Ceuta',
  'Ciudad Real',
  'Córdoba',
  'Cuenca',
  'Girona',
  'Granada',
  'Guadalajara',
  'Gipuzkoa',
  'Huelva',
  'Huesca',
  'Jaén',
  'La Rioja Province',
  'Las Palmas',
  'León',
  'Lleida',
  'Lugo',
  'Madrid Province',
  'Málaga',
  'Melilla',
  'Murcia',
  'Navarra',
  'Ourense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia Seville',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Biscay',
  'Zamora Zaragoza',
];

export const IT_PROVINCES = [
  'Agrigento ',
  'Alessandria',
  'Ancona',
  'Aosta Valley',
  'Arezzo',
  'Ascoli Piceno',
  'Asti',
  'Avellino',
  'Bari',
  'Barletta-Andria-Trani',
  'Belluno',
  'Benevento',
  'Bergamo',
  'Biella',
  'Bologna',
  'South Tyrol',
  'Brescia',
  'Brindisi',
  'Cagliari',
  'Caltanissetta',
  'Campobasso',
  'Carbonia-Iglesias',
  'Caserta',
  'Catania',
  'Catanzaro',
  'Chieti',
  'Como',
  'Cosenza',
  'Cremona',
  'Crotone',
  'Cuneo',
  'Enna',
  'Fermo',
  'Ferrara',
  'Florence',
  'Foggia',
  'Forlì-Cesena',
  'Frosinone',
  'Genoa',
  'Gorizia',
  'Grosseto',
  'Imperia',
  'Isernia',
  "L'Aquila",
  'La Spezia',
  'Latina',
  'Lecce',
  'Lecco',
  'Livorno',
  'Lodi',
  'Lucca Macerata',
  'Mantua',
  'Massa and Carrara',
  'Matera',
  'Medio Campidano',
  'Messina',
  'Milan',
  'Modena',
  'Monza and Brianza',
  'Naples Novara',
  'Nuoro',
  'Ogliastra Olbia-Tempio',
  'Oristano',
  'Padua',
  'Palermo',
  'Parma',
  'Pavia',
  'Perugia',
  'Pesaro and Urbino',
  'Pescara',
  'Piacenza',
  'Pisa',
  'Pistoia',
  'Pordenone',
  'Potenza',
  'Prato Ragusa',
  'Ravenna',
  'Reggio Calabria',
  'Reggio Emilia',
  'Rieti',
  'Rimini',
  'Rome Rovigo',
  'Salerno Sassari',
  'Savona Siena',
  'Syracuse',
  'Sondrio',
  'Taranto',
  'Teramo',
  'Terni',
  'Turin',
  'Trapani',
  'Trentino',
  'Treviso',
  'Trieste',
  'Udine',
  'Varese',
  'Venice',
  'Verbano-Cusio-Ossola',
  'Vercelli',
  'Verona',
  'Vibo Valentia',
  'Vicenza Viterbo',
];

type DynamicDropDownItems = {
  [countryCode: string]: string[];
};

export const DYNAMIC_DROPDOWN_ITEMS: DynamicDropDownItems = {
  IE: IE_COUNTIES,
  PT: PT_REGIONS,
  RO: RO_CONTIES,
  ES: ES_PROVINCES,
  IT: IT_PROVINCES,
};

export const getCountryPhoneCode = (currentCountry: string) => {
  const countryCodes = getAllCountryPhoneCodes();
  const countryCode = countryCodes.find(
    (country) => country.value === currentCountry
  ) as CountryCodeOption;

  return countryCode.key;
};

export type ADDRESS_FIELD_NAMES =
  | 'email'
  | 'country'
  | 'first_name'
  | 'last_name'
  | 'address'
  | 'address2'
  | 'zip'
  | 'city'
  | 'phone'
  | 'county'
  | 'province'
  | 'region';

export const getFieldsSequenceForConfirmationPage = (country: string) => {
  const GENERIC_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'zip',
    'city',
    'phone',
  ];
  const LV_LT_MA_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'city',
    'zip',
    'phone',
  ];
  const HU_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'zip',
    'city',
    'address',
    'address2',
    'phone',
  ];
  const IE_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'city',
    'county',
    'zip',
    'phone',
  ];
  const PT_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'zip',
    'city',
    'region',
    'phone',
  ];
  const RO_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'zip',
    'city',
    'county',
    'phone',
  ];
  const ES_IT_ORDER: ADDRESS_FIELD_NAMES[] = [
    'email',
    'country',
    'first_name',
    'last_name',
    'address',
    'address2',
    'zip',
    'city',
    'province',
    'phone',
  ];

  const sequenceCountryFields: { [key: string]: ADDRESS_FIELD_NAMES[] } = {
    EN: GENERIC_ORDER,
    GR: GENERIC_ORDER,
    FR: GENERIC_ORDER,
    SK: GENERIC_ORDER,
    BG: GENERIC_ORDER,
    SE: GENERIC_ORDER,
    FI: GENERIC_ORDER,
    HR: GENERIC_ORDER,
    EE: GENERIC_ORDER,
    AT: GENERIC_ORDER,
    DE: GENERIC_ORDER,
    NL: GENERIC_ORDER,
    BE: GENERIC_ORDER,
    SI: GENERIC_ORDER,
    CZ: GENERIC_ORDER,
    PL: GENERIC_ORDER,
    DK: GENERIC_ORDER,
    LU: GENERIC_ORDER,
    LV: LV_LT_MA_ORDER,
    LT: LV_LT_MA_ORDER,
    MA: LV_LT_MA_ORDER,
    HU: HU_ORDER,
    IE: IE_ORDER,
    PT: PT_ORDER,
    RO: RO_ORDER,
    ES: ES_IT_ORDER,
    IT: ES_IT_ORDER,
  };

  return sequenceCountryFields[country] || [];
};

export const isKetoChocolateOverlayNeeded = (user: User): boolean => {
  if (!isKetoChocolateBarsAllowed(user)) {
    return false;
  }
  if (isAccessAvailable(user, ItemType.ketoChocolateBars)) {
    return false;
  }
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );
  if (activeSubscription) {
    return false;
  }
  if (
    user.available_offers &&
    !user.available_offers.universal_keto_chocolate_bars
  ) {
    return false;
  }

  return true;
};

export const isKetoBarsSubscriptionWithoutShippingAddress = (
  user: User
): boolean => {
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );

  if (!isAccessAvailable(user, ItemType.ketoChocolateBars)) {
    return false;
  }
  if (!activeSubscription) {
    return false;
  }
  if (activeSubscription.shipping_address) {
    return false;
  }

  return true;
};

export const isKetoBarsSubscriptionWithShippingAddress = (
  user: User
): boolean => {
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );

  if (!isAccessAvailable(user, ItemType.ketoChocolateBars)) {
    return false;
  }
  if (!activeSubscription) {
    return false;
  }
  if (!activeSubscription.shipping_address) {
    return false;
  }

  return true;
};

export const isKetoBarsSubscriptionUnsubscribed = (user: User) => {
  const activeSubscription = getActiveSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );

  if (!isAccessAvailable(user, ItemType.ketoChocolateBars)) {
    return false;
  }
  if (activeSubscription) {
    return false;
  }
  const unsubscribedSubscription = getUnsubscribedSubscriptionByItemType(
    user,
    ItemType.ketoChocolateBars
  );
  if (!unsubscribedSubscription) {
    return false;
  }

  return true;
};

export const isKetoBarsInexistantForUser = (user: User) => {
  if (isAccessAvailable(user, ItemType.ketoChocolateBars)) {
    return false;
  }
  if (!user.available_offers) {
    return false;
  }
  if (!user.available_offers.profile_keto_chocolate_bars) {
    return false;
  }

  return true;
};

export const getDynamicContextualData = (forwardPath: string, t: TFunction) => {
  let returnData: DynamicContextualData = {
    offer_type: 'profile_keto_chocolate_bars',
    source: KETO_CHOCOLATE_OFFER_ACTIVITY_SOURCE.contextualOfferProfile,
    utms: {
      utm_source: t('domains:domain.website'),
      utm_medium: 'profile_flow',
      utm_campaign: 'keto_chocolate',
    },
  };

  if (forwardPath !== '/program') {
    returnData = {
      ...returnData,
      offer_type: 'universal_keto_chocolate_bars',
      source: KETO_CHOCOLATE_OFFER_ACTIVITY_SOURCE.contextualOfferProgram,
      utms: {
        ...returnData.utms,
        utm_medium: 'contextual_flow',
      },
    };
  }

  return returnData;
};
