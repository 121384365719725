import { getCurrentLocale } from 'i18n';
import i18next from 'i18next';

import {
  MealType,
  HELPDESK_LINKS_FOR_PRODUCT,
  HELPDESK_CANCELLATION_FEE_LINKS,
} from 'types/program';
import settings from 'helpers/settings';

import BreakfastLargeIcon from 'assets/images/program/meal/breakfast.svg';
import BreakfastLargeIconKeto from 'assets/images/program/meal/keto/breakfastKeto.svg';
import BreakfastLargeIconFasting from 'assets/images/program/meal/fasting/breakfastFasting.svg';
import BreakfastLargeIconHealthgenom from 'assets/images/program/meal/healthgenom/breakfastHealthgenom.svg';
import LunchLargeIcon from 'assets/images/program/meal/lunch.svg';
import LunchLargeIconKeto from 'assets/images/program/meal/keto/lunchKeto.svg';
import LunchLargeIconFasting from 'assets/images/program/meal/fasting/lunchFasting.svg';
import LunchLargeIconHealthgenom from 'assets/images/program/meal/healthgenom/lunchHealthgenom.svg';
import DinnerLargeIcon from 'assets/images/program/meal/dinner.svg';
import DinnerLargeIconKeto from 'assets/images/program/meal/keto/dinnerKeto.svg';
import DinnerLargeIconFasting from 'assets/images/program/meal/fasting/dinnerFasting.svg';
import DinnerLargeIconHealthgenom from 'assets/images/program/meal/healthgenom/dinnerHealthgenom.svg';
import SnackLargeIcon from 'assets/images/program/meal/snack.svg';
import SnackLargeIconKeto from 'assets/images/program/meal/keto/snackKeto.svg';
import SnackLargeIconFasting from 'assets/images/program/meal/fasting/snackFasting.svg';
import SnackLargeIconHealthgenom from 'assets/images/program/meal/healthgenom/snackHealthgenom.svg';
import TrickLargeIcon from 'assets/images/program/meal/trick.svg';
import TrickLargeIconKeto from 'assets/images/program/meal/keto/trickKeto.svg';
import TrickLargeIconFasting from 'assets/images/program/meal/fasting/trickFasting.svg';
import TrickLargeIconHealthgenom from 'assets/images/program/meal/healthgenom/trickHealthgenom.svg';

import BreakfastSmallIcon from 'assets/images/program/calendar/breakfast.svg';
import BreakfastSmallIconKeto from 'assets/images/program/calendar/keto/breakfastKeto.svg';
import BreakfastSmallIconFasting from 'assets/images/program/calendar/fasting/breakfastFasting.svg';
import BreakfastSmallIconHealthgenom from 'assets/images/program/calendar/healthgenom/breakfastHealthgenom.svg';
import LunchSmallIcon from 'assets/images/program/calendar/lunch.svg';
import LunchSmallIconKeto from 'assets/images/program/calendar/keto/lunchKeto.svg';
import LunchSmallIconFasting from 'assets/images/program/calendar/fasting/lunchFasting.svg';
import LunchSmallIconHealthgenom from 'assets/images/program/calendar/healthgenom/lunchHealthgenom.svg';
import DinnerSmallIcon from 'assets/images/program/calendar/dinner.svg';
import DinnerSmallIconKeto from 'assets/images/program/calendar/keto/dinnerKeto.svg';
import DinnerSmallIconFasting from 'assets/images/program/calendar/fasting/dinnerFasting.svg';
import DinnerSmallIconHealthgenom from 'assets/images/program/calendar/healthgenom/dinnerHealthgenom.svg';
import SnackSmallIcon from 'assets/images/program/calendar/snack.svg';
import SnackSmallIconKeto from 'assets/images/program/calendar/keto/snackKeto.svg';
import SnackSmallIconFasting from 'assets/images/program/calendar/fasting/snackFasting.svg';
import SnackSmallIconHealthgenom from 'assets/images/program/calendar/healthgenom/snackHealthgenom.svg';
import TrickSmallIcon from 'assets/images/program/calendar/trick.svg';
import TrickSmallIconKeto from 'assets/images/program/calendar/keto/trickKeto.svg';
import TrickSmallIconFasting from 'assets/images/program/calendar/fasting/trickFasting.svg';
import TrickSmallIconHealthgenom from 'assets/images/program/calendar/healthgenom/trickHealthgenom.svg';

import { getCurrentTheme, isMonacofit, isFasting } from './themeHelper';

export const modalBackgroundToggle = (value) => {
  if (Object.values(value)[0]) {
    document.body.classList.add('modal-open');
  } else {
    document.body.className = document.body.className.replace('modal-open', '');
  }
};

export const hasGhostExercise = (workout) =>
  workout.workout_exercises.main &&
  workout.workout_exercises.main.length === 1 &&
  workout.workout_exercises.main[0].visibility[0] === 'ghost';

export const hasNoExercise = (workout) =>
  workout.workout_exercises.main && workout.workout_exercises.main.length === 0;

export const supportServiceWorkers = () =>
  navigator.userAgent.indexOf('MSIE') <= -1 ||
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isMobile = () => {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const freePlanMeals = (dayId) => [
  {
    id: 1 + dayId,
    meal_type: 'breakfast',
    type: 'day_meal',
    state: 'draft',
  },
  {
    id: 2 + dayId,
    meal_type: 'lunch',
    type: 'day_meal',
    state: 'draft',
  },
  {
    id: 3 + dayId,
    meal_type: 'dinner',
    type: 'day_meal',
    state: 'draft',
  },
  {
    id: 4 + dayId,
    meal_type: 'snack',
    type: 'day_meal',
    state: 'draft',
  },
];

export const numToCurrency = (number) => {
  const stringNumber = number.toString();
  if (stringNumber.length === 2)
    return `0.${stringNumber.slice(stringNumber.length - 2)}`;
  if (stringNumber.length === 1)
    return `0.0${stringNumber.slice(stringNumber.length - 1)}`;
  return `${stringNumber.slice(
    0,
    stringNumber.length - 2
  )}.${stringNumber.slice(stringNumber.length - 2)}`;
};

const getMealIconSet = (size) => {
  let icons;
  switch (getCurrentTheme()) {
    case 'diet':
      if (size === 'small') {
        icons = {
          breakfast: BreakfastSmallIcon,
          lunch: LunchSmallIcon,
          dinner: DinnerSmallIcon,
          snack: SnackSmallIcon,
          trick: TrickSmallIcon,
        };
      } else {
        icons = {
          breakfast: BreakfastLargeIcon,
          lunch: LunchLargeIcon,
          dinner: DinnerLargeIcon,
          snack: SnackLargeIcon,
          trick: TrickLargeIcon,
        };
      }
      return icons;
    case 'keto':
      if (size === 'small') {
        icons = {
          breakfast: BreakfastSmallIconKeto,
          lunch: LunchSmallIconKeto,
          dinner: DinnerSmallIconKeto,
          snack: SnackSmallIconKeto,
          trick: TrickSmallIconKeto,
        };
      } else {
        icons = {
          breakfast: BreakfastLargeIconKeto,
          lunch: LunchLargeIconKeto,
          dinner: DinnerLargeIconKeto,
          snack: SnackLargeIconKeto,
          trick: TrickLargeIconKeto,
        };
      }
      return icons;
    case 'ketogo':
      if (size === 'small') {
        icons = {
          breakfast: BreakfastSmallIconKeto,
          lunch: LunchSmallIconKeto,
          dinner: DinnerSmallIconKeto,
          snack: SnackSmallIconKeto,
          trick: TrickSmallIconKeto,
        };
      } else {
        icons = {
          breakfast: BreakfastLargeIconKeto,
          lunch: LunchLargeIconKeto,
          dinner: DinnerLargeIconKeto,
          snack: SnackLargeIconKeto,
          trick: TrickLargeIconKeto,
        };
      }
      return icons;
    case 'fasting':
      if (size === 'small') {
        icons = {
          breakfast: BreakfastSmallIconFasting,
          lunch: LunchSmallIconFasting,
          dinner: DinnerSmallIconFasting,
          snack: SnackSmallIconFasting,
          trick: TrickSmallIconFasting,
        };
      } else {
        icons = {
          breakfast: BreakfastLargeIconFasting,
          lunch: LunchLargeIconFasting,
          dinner: DinnerLargeIconFasting,
          snack: SnackLargeIconFasting,
          trick: TrickLargeIconFasting,
        };
      }
      return icons;
    case 'healthgenom':
      if (size === 'small') {
        icons = {
          breakfast: BreakfastSmallIconHealthgenom,
          lunch: LunchSmallIconHealthgenom,
          dinner: DinnerSmallIconHealthgenom,
          snack: SnackSmallIconHealthgenom,
          trick: TrickSmallIconHealthgenom,
        };
      } else {
        icons = {
          breakfast: BreakfastLargeIconHealthgenom,
          lunch: LunchLargeIconHealthgenom,
          dinner: DinnerLargeIconHealthgenom,
          snack: SnackLargeIconHealthgenom,
          trick: TrickLargeIconHealthgenom,
        };
      }
      return icons;
    default:
      return null;
  }
};

export const mealIcon = (mealType, size) => {
  const icon = getMealIconSet(size);

  switch (mealType) {
    case MealType.breakfast:
      return icon.breakfast;
    case MealType.lunch:
      return icon.lunch;
    case MealType.dinner:
      return icon.dinner;
    case MealType.snack:
      return icon.snack;
    case MealType.trick:
    default:
      return icon.trick;
  }
};

export const isHelpdeskFooter = () => {
  const theme = getCurrentTheme();
  const locale = getCurrentLocale();

  if (!['keto', 'ketogo', 'fasting', 'healthgenom'].includes(theme)) {
    return false;
  }

  const translationExists =
    i18next.getResource(locale, 'helpdesk', 'footer.text') !== undefined;
  // Check wether this translation string exists in the current locale
  if (!translationExists) {
    return false;
  }

  return true;
};

export const localeHasHelpdeskLink = (theme, locale) => {
  if (isMonacofit() || isFasting()) {
    return false;
  }

  return HELPDESK_LINKS_FOR_PRODUCT[theme][locale];
};

export const getHelpdeskLink = () => {
  const theme = getCurrentTheme();
  const locale = getCurrentLocale();

  const linksPerTheme = HELPDESK_LINKS_FOR_PRODUCT[theme];

  if (!linksPerTheme) return null;

  return linksPerTheme[locale] || linksPerTheme.en;
};

export const hideMealStep = () => settings.SHOW_MEALSURVEY_MEAL_STEP === false;

export const getCancellationFeeLink = () => {
  const theme = getCurrentTheme();
  const locale = getCurrentLocale();

  const linksPerTheme = HELPDESK_CANCELLATION_FEE_LINKS[theme];

  if (!linksPerTheme) return null;

  return linksPerTheme[locale] || linksPerTheme.en;
};
