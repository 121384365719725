import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormControl, Select, CircularProgress } from '@material-ui/core';
import { getCurrentLocale } from 'i18n';
import {
  getStyle,
  getCurrentTheme,
  isMaterialUI,
  getMaterialUIColor,
} from 'helpers/themeHelper';
import { setLocale } from 'actions/programActions';
import settings from 'helpers/settings';

import base from './styles/LanguageDropdown.module.scss';
import diet from './styles/LanguageDropdown_diet.module.scss';
import keto from './styles/LanguageDropdown_keto.module.scss';
import ketogo from './styles/LanguageDropdown_ketogo.module.scss';
import fasting from './styles/LanguageDropdown_fasting.module.scss';
import healthgenom from './styles/LanguageDropdown_healthgenom.module.scss';

const s = getStyle(base, diet, keto, ketogo, fasting, healthgenom);

const localeData = {
  en: {
    emoji: '🇬🇧',
    name: 'English',
  },
  de: {
    emoji: '🇩🇪',
    name: 'Deutsche',
  },
  lv: {
    emoji: '🇱🇻',
    name: 'Latviešu',
  },
  lt: {
    emoji: '🇱🇹',
    name: 'Lietuvos',
  },
  ro: {
    emoji: '🇷🇴',
    name: 'Română',
  },
  hu: {
    emoji: '🇭🇺',
    name: 'Magyar',
  },
  ru: {
    emoji: '🇷🇺',
    name: 'Русский',
  },
  hr: {
    emoji: '🇭🇷',
    name: 'Hrvatski',
  },
  es: {
    emoji: '🇪🇸',
    name: 'Español',
  },
  et: {
    emoji: '🇪🇪',
    name: 'Eesti',
  },
  fr: {
    emoji: '🇫🇷',
    name: 'Français',
  },
  sk: {
    emoji: '🇸🇰',
    name: 'Slovenský',
  },
  gr: {
    emoji: '🇬🇷',
    name: 'Ελληνικά',
  },
  el: {
    emoji: '🇬🇷',
    name: 'Ελληνικά',
  },
  cs: {
    emoji: '🇨🇿',
    name: 'Český',
  },
  pl: {
    emoji: '🇵🇱',
    name: 'Polski',
  },
  pt: {
    emoji: '🇵🇹',
    name: 'Português',
  },
  bg: {
    emoji: '🇧🇬',
    name: 'Български',
  },
  nl: {
    emoji: '🇳🇱',
    name: 'Nederlands',
  },
  da: {
    emoji: '🇩🇰',
    name: 'Dansk',
  },
  it: {
    emoji: '🇮🇹',
    name: 'Italiano',
  },
  ka: {
    emoji: '🇬🇪',
    name: 'ქართული',
  },
  sr: {
    emoji: '🇷🇸',
    name: 'Srpski',
  },
  uk: {
    emoji: '🇺🇦',
    name: 'Українська',
  },
  no: {
    emoji: '🇳🇴',
    name: 'Norsk',
  },
  sv: {
    emoji: '🇸🇪',
    name: 'Svenska',
  },
  fi: {
    emoji: '🇫🇮',
    name: 'Suomi',
  },
  he: {
    emoji: '🇮🇱',
    name: 'עִברִית',
  },
};

const locales = () => {
  let locales;

  switch (getCurrentTheme()) {
    case 'diet':
      locales = [
        'en',
        'de',
        'lv',
        'lt',
        'ro',
        'hu',
        'es',
        'et',
        'ru',
        'fr',
        'gr',
        'hr',
        'sk',
        'cs',
      ];
      break;
    case 'keto':
      locales = [
        'en',
        'lt',
        'et',
        'cs',
        'es',
        'fr',
        'de',
        'ro',
        'el',
        'hu',
        'hr',
        'pl',
        'pt',
        'bg',
        'sk',
        'ru',
        'nl',
        'da',
        'it',
        'sr',
        'ka',
        'uk',
        'no',
        'sv',
        'fi',
        'he',
      ];
      break;
    case 'ketogo':
      locales = [
        'en',
        'lt',
        'et',
        'cs',
        'es',
        'fr',
        'de',
        'ro',
        'el',
        'hu',
        'hr',
        'pl',
        'pt',
        'bg',
        'sk',
        'ru',
        'nl',
        'no',
        'da',
        'it',
        'sr',
        'ka',
        'fi',
        'sv',
        'uk',
      ];
      break;
    case 'fasting':
      locales = [
        'en',
        'es',
        'cs',
        'uk',
        'hu',
        'no',
        'ro',
        'ru',
        'el',
        'fi',
        'bg',
        'pl',
        'sk',
        'fr',
        'he',
      ];
      break;
    case 'healthgenom':
      locales = ['en', 'es', 'it'];
      break;
    case 'monacofit':
    default:
      locales = [
        'en',
        'lv',
        'ru',
        'hr',
        'lt',
        'ro',
        'sk',
        'et',
        'fr',
        'es',
        'hu',
        'cs',
        'de',
        'gr',
      ];
      break;
  }

  locales = locales.sort((a, b) =>
    localeData[a].name
      .toLowerCase()
      .localeCompare(localeData[b].name.toLowerCase())
  );

  // move english first
  locales.forEach((locale, i) => {
    if (locale === 'en') {
      locales.splice(i, 1);
      locales.unshift(locale);
    }
  });

  return locales;
};

class LanguageDropdown extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    currentLocale: PropTypes.object,
    setLocale: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.locale = getCurrentLocale();
  }

  optionChange = (e) => {
    const { onChange, setLocale } = this.props;

    this.locale = e.target.value;
    onChange(e);

    setLocale(this.locale);
  };

  showOptions = () =>
    locales().map((locale, index) => (
      <option
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        value={locale}
        className="language-dropdown__drop-item"
      >
        {settings.HAS_LANGUAGE_DROPDOWN_EMOJI && `${localeData[locale].emoji} `}
        {localeData[locale].name}
      </option>
    ));

  render = () => {
    const {
      currentLocale: { isLoading },
    } = this.props;

    if (isMaterialUI()) {
      const color = getMaterialUIColor();

      return (
        <div className={s.container}>
          <FormControl variant="outlined" className={s.fullWidth}>
            <Select
              native
              className={s.select}
              name="language"
              defaultValue={getCurrentLocale()}
              disabled={isLoading}
              onChange={(e) => this.optionChange(e)}
              loading={() => <CircularProgress size={25} color={color} />}
            >
              {this.showOptions()}
            </Select>
            {isLoading && (
              <CircularProgress className={s.loader} size={25} color={color} />
            )}
          </FormControl>
        </div>
      );
    }

    return (
      <div className={s.container}>
        {isLoading ? (
          <svg
            className={s.loader}
            id="load"
            x="0px"
            y="0px"
            viewBox="0 0 150 150"
          >
            <circle id="loading-inner" cx={75} cy={75} r={60} />
          </svg>
        ) : (
          <select
            className={s.select}
            name="language"
            defaultValue={getCurrentLocale()}
            disabled={isLoading}
            onChange={(e) => this.optionChange(e)}
          >
            {this.showOptions()}
          </select>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.rootReducer.isLoading,
  currentLocale: state.rootReducer.currentLocale,
});

const mapDispatchToProps = {
  setLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
